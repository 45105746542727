<template>
  <section class="servicios">
    <article class="generalbluecard">
      <h2 class="generalcardtitle">
        {{ dbStore.allServicesData.firstSectionTitle }}
      </h2>
    </article>

    <article class="generalrightcard">
      <div class="generalcardwrap">
        <h2 class="generalcardtext1 margin-bottom-20">
          {{ dbStore.allServicesData.firstSectionSubtitle }}
        </h2>

        <div
          class="cardlist flex items-center"
          v-for="(item, index) in dbStore.allServicesData.firstSectionList"
          :key="index"
        >
          <p class="generalcardtext relative margin-top-bottom-5 padding-left-30">
              <span class="material-icons-outlined gray-dark-light position absolute top-5 left-0">grid_view</span>
              <span class="generalcardtext-line2">
              {{ item.item }}</span>
           </p>
        </div>
      </div>
    </article>
  </section>

  <div class="whitespace"></div>

  <section
    class="calidad"
  >
    <img :src="dbStore.allServicesData.imgURL" alt="Servicios" />
    <article class="calidadwrap absolute top-0 left-0 full-width full-height padding-top-bottom-40">
      <div class="container container_md flex row wrap items-center full-height">
        <div>
          <h2 class="calidadtext margin-bottom-50">
            {{ dbStore.allServicesData.secondSectionTitle }}
          </h2>
          <p class="calidadparagraph">
            <span class="bgblack">{{
              dbStore.allServicesData.secondSectionDescription
            }}</span>
          </p>
        </div>
      </div>
    </article>
  </section>

  <div class="whitespace"></div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useDbStore } from "@/stores/dbStore";
export default {
  name: "Servicios",
  setup() {
    const dbStore = useDbStore();
    const dataProducts = reactive({});

    return {
      ...toRefs(dataProducts),
      dbStore,
    };
  },
};
</script>
